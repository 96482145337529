import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingPages } from '@src/app/shared/enums/enums';

const routes: Routes = [
  {
    path: AppRoutingPages.Home,
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: AppRoutingPages.AboutUs,
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: AppRoutingPages.GardenServices,
    loadChildren: () => import('./modules/garden-services/garden-services.module').then(m => m.GardenServicesModule)
  },
  {
    path: AppRoutingPages.Contract,
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: AppRoutingPages.Realizations,
    loadChildren: () => import('./modules/realizations/realizations.module').then(m => m.RealizationsModule)
  },
  {
    path: '',
    redirectTo: AppRoutingPages.Home,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
