<div class="quick-menu-element-wrapper">
  <i class="icon-image" [ngClass]="imageClassName"></i>
  <div class="title-wrapper">
    <p class="upper-title">{{ upperTitle }}</p>
    <p
      class="lower-title"
      *ngIf="(activeBreakpoint$ | async) !== breakpoints.Mobile"
    >
      {{ lowerTitle }}
    </p>
  </div>
</div>
