import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BreakpointDetectionService,
  Breakpoints
} from '@src/app/shared/services/breakpoint-detection.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public breakpoints: typeof Breakpoints = Breakpoints;
  public activeBreakpoint$: Observable<Breakpoints>;

  constructor(private breakpointDetectionService: BreakpointDetectionService) {
    this.activeBreakpoint$ = this.breakpointDetectionService.activeBreakpoint$;
  }

  public onInstagramClick(): void {
    window.location.href = 'https://instagram.com/mdgardendamian?igshid=YmMyMTA2M2Y=';
  }

  public onFacebookClick(): void {
    window.location.href = 'https://www.facebook.com/profile.php?id=100064159594173';
  }
}
