export const locales = {
  quickMenuElements: {
    phone: {
      title: 'Telefon kontaktowy',
      value: '+48 660 359 497'
    },
    email: {
      title: 'Adres email',
      value: 'kontakt@md-garden.pl'
    },
    address: {
      title: 'Adres siedziby',
      value: '03-982 Warszawa'
    }
  }
};
