import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { QuickMenuElementComponent } from '@src/app/components/quick-menu-element/quick-menu-element.component';
import { windowProvider } from '@src/app/shared/providers/window.provider';
import { BreakpointDetectionService } from '@src/app/shared/services/breakpoint-detection.service';
import { DocumentService } from '@src/app/shared/services/document.service';
import { documentProvider } from '@src/app/shared/providers/document.provider';
import { isPlatformBrowser } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    QuickMenuElementComponent,
    FooterComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    MatIconModule,
    MatButtonModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    windowProvider,
    documentProvider,
    BreakpointDetectionService,
    DocumentService
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
