import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { $DOCUMENT } from '../providers/document.provider';
import { DocumentInterface } from '../interfaces/document.interface';
import { Browserable } from '../decorators/browserable';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              @Inject($DOCUMENT) private $document: DocumentInterface) {
  }

  @Browserable
  public isCreateElementFunction(): boolean {
    return (typeof this.$document.createElement === 'function');
  }

  @Browserable
  public addClassToBody(className: string): void {
    if (!this.$document) {
      console.warn(
        'addClassToBody: Unable to read $document: ',
        this.$document
      );
      return;
    }

    try {
      this.$document.body.classList.add(className);
    } catch (error) {
      console.error(
        'addClassToBody: Unable to add class to body: ',
        error
      );
    }
  }

  @Browserable
  public removeClassFromBody(className: string): void {
    if (!this.$document) {
      console.warn(
        'removeClassFromBody: Unable to read $document: ',
        this.$document
      );
      return;
    }

    try {
      this.$document.body.classList.remove(className);
    } catch (error) {
      console.error(
        'removeClassFromBody: Unable to add class to body: ',
        error
      );
    }
  }

}
