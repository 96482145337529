import { isPlatformBrowser } from '@angular/common';

/**
 * Dekorator wymaga:
 * - zaimportowania pliku z definicją dekoratora (ten plik)
 * - zainjectowania `platformId` za pomocą liniki:
 *
 * ```
 * @Inject(PLATFORM_ID) private platformId: string
 * ```
 */
export function Browserable(target: Object, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  const cachedFunction = descriptor.value;

  let resultFunction = function (): void {
    return;
  };

  descriptor.value = function (...args): Function {
    if (typeof this.platformId === 'undefined') {
      throw new Error('platformId is not defined');
    }

    if (isPlatformBrowser(this.platformId)) {
      resultFunction = cachedFunction.apply(this, args);
    }

    return resultFunction;
  };

  return descriptor;
}
