export function getWindowRef(): Window {
  return (typeof window !== 'undefined') ? window : {} as Window;
}

export class $WINDOW {
}

export let windowProvider = {
  provide: $WINDOW,
  useFactory: getWindowRef
};
