export function getDocumentRef(): Document {
  return (typeof document !== 'undefined') ? document : {} as Document ;
}

export class $DOCUMENT {
}

export let documentProvider = {
  provide: $DOCUMENT,
  useFactory: getDocumentRef
};
