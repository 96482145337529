import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import {
  BreakpointDetectionService,
  Breakpoints
} from '@src/app/shared/services/breakpoint-detection.service';
import { OVERFLOW_CLASS_NAME } from '@src/app/shared/consts/consts';
import { DocumentService } from '@src/app/shared/services/document.service';
import {
  AppRoutingPages, ServicesDetailsRoutingPages,
  ServicesRoutingPages
} from '@src/app/shared/enums/enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  public breakpoints: typeof Breakpoints = Breakpoints;
  public activeBreakpoint$: Observable<Breakpoints>;
  public appRoutingPages: typeof AppRoutingPages = AppRoutingPages;
  public servicesRoutingPages: typeof ServicesRoutingPages = ServicesRoutingPages;
  public servicesDetailsRoutingPages: typeof ServicesDetailsRoutingPages = ServicesDetailsRoutingPages;

  public isMobileMenuOpened = false;
  public isDropdownMenuOpened = false;

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private breakpointDetectionService: BreakpointDetectionService
  ) {
    this.activeBreakpoint$ = this.breakpointDetectionService.activeBreakpoint$;
  }

  public onLogoClick(): void {
    this.router.navigate([ 'home' ]);
  }

  public onMenuOpenClick(): void {
    this.isMobileMenuOpened = true;
    this.documentService.addClassToBody(OVERFLOW_CLASS_NAME);
  }

  public onMenuCloseClick(): void {
    this.isMobileMenuOpened = false;
    this.documentService.removeClassFromBody(OVERFLOW_CLASS_NAME);
  }
}
