import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Breakpoints {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointDetectionService {
  public activeBreakpoint$: Observable<Breakpoints>;

  constructor(private breakpointObserver: BreakpointObserver) {
    const mobileBreakpoint = '(max-width: 767px)';
    const tabletBreakpoint = '(min-width: 768px) and (max-width:' +
      ' 1259px)';
    const desktopBreakpoint = '(min-width: 1260px)';

    this.activeBreakpoint$ = this.breakpointObserver
      .observe([ mobileBreakpoint, tabletBreakpoint, desktopBreakpoint ])
      .pipe(
        map((state: BreakpointState) => {
          if (state.breakpoints[ mobileBreakpoint ]) {
            return Breakpoints.Mobile;
          }
          if (state.breakpoints[ tabletBreakpoint ]) {
            return Breakpoints.Tablet;
          }
          if (state.breakpoints[ desktopBreakpoint ]) {
            return Breakpoints.Desktop;
          }
          return Breakpoints.Mobile;
        })
      );

  }
}
