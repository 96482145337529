<header class="header">
  <div class="header-wrapper" *ngIf="(activeBreakpoint$ | async) !==
  breakpoints.Mobile; else mobileHeader">
    <div class="quick-contact-menu-wrapper">
      <div class="quick-contact-wrapper main-content">
        <div class="logo-wrapper">
          <i class="logo-icon logo-background" (click)="onLogoClick()"></i>
        </div>
        <ul class="quick-menu">
          <li class="quick-menu-element">
            <app-quick-menu-element
              [upperTitle]="'Telefon kontaktowy'"
              [lowerTitle]="'+48 660 359 497'"
              [imageClassName]="'phone-background'"
            ></app-quick-menu-element>
          </li>
          <li class="quick-menu-element">
            <app-quick-menu-element
              [upperTitle]="'Adres email'"
              [lowerTitle]="'kontakt@md-garden.pl'"
              [imageClassName]="'email-background'"
            ></app-quick-menu-element>
          </li>
          <li class="quick-menu-element office-address">
            <app-quick-menu-element
              [upperTitle]="'Usługi Warszawa i powiaty sąsiadujące'"
              [imageClassName]="'address-background'"
            ></app-quick-menu-element>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-wrapper main-content">
        <ul class="main-menu">
          <li
            [routerLink]="appRoutingPages.AboutUs"
            routerLinkActive="active-link"
          >
            o nas
          </li>
          <li
            (mouseenter)="isDropdownMenuOpened = true"
            (mouseleave)="isDropdownMenuOpened = false"
            [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesList
                ]"
            routerLinkActive="active-link"
          >
            usługi
            <ul
              class="submenu"
              [ngClass]="{'show': isDropdownMenuOpened}"
              (mouseleave)="isDropdownMenuOpened = false"
            >
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.IrrigationSystems
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Systemy nawadniaące
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.Lawns
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                trawniki
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.Plantings
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Nasadzenia i rabaty
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.CareAndRevitalization
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Pielęgnacja i rewitalizacja
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.Drainage
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Drenaże
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.StartTheSeason
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Start sezonu
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.YearRoundCare
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Całoroczna pielęgnacja
              </li>
              <li
                [routerLink]="[
                  appRoutingPages.GardenServices,
                  servicesRoutingPages.GardenServicesDetails,
                  servicesDetailsRoutingPages.PrepareForWinter
                ]"
                routerLinkActive="active-link"
                (click)="$event.stopPropagation()"
              >
                Przygotowanie do zimy
              </li>
            </ul>
          </li>
          <li
            [routerLink]="appRoutingPages.Realizations"
            routerLinkActive="active-link"
          >
            realizacje
          </li>
          <li
            [routerLink]="appRoutingPages.Contract"
            routerLinkActive="active-link"
          >
            kontakt
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ng-template #mobileHeader>
    <div class="mobile-header-wrapper">
      <div class="mobile-quick-contact-menu-wrapper">
        <ul class="quick-menu">
          <li class="quick-menu-element">
            <app-quick-menu-element
              [upperTitle]="'+48 660 359 497'"
              [imageClassName]="'phone-background'"
            ></app-quick-menu-element>
          </li>
          <li class="quick-menu-element">
            <app-quick-menu-element
              [upperTitle]="'kontakt@md-garden.pl'"
              [imageClassName]="'email-background'"
            ></app-quick-menu-element>
          </li>
        </ul>
        <div class="mobile-submenu-wrapper">
          <i class="mobile-logo-icon logo-background"
             (click)="onLogoClick()"></i>
          <button mat-icon-button (click)="onMenuOpenClick()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <nav class="mobile-navigation" [ngClass]="isMobileMenuOpened ? 'opened' : 'closed'">
    <i class="close" (click)="onMenuCloseClick()"></i>
    <ul class="menu-nav">
      <li class="menu-nav-element">
        <a
          class="element-text"
          [routerLink]="appRoutingPages.AboutUs"
          routerLinkActive="active-link"
          (click)="onMenuCloseClick()"
        >
          o nas
        </a>
      </li>
      <li class="menu-nav-element">
        <a
          class="element-text"
          [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesList
            ]"
          routerLinkActive="active-link"
          (click)="onMenuCloseClick()"
        >
          usługi
        </a>
        <ul class="mobile-submenu">
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.IrrigationSystems
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Systemy nawadniaące
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.Lawns
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            trawniki
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.Plantings
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Nasadzenia i rabaty
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.CareAndRevitalization
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Pielęgnacja i rewitalizacja
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.Drainage
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Drenaże
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.StartTheSeason
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Start sezonu
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.YearRoundCare
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Całoroczna pielęgnacja
          </li>
          <li
            [routerLink]="[
              appRoutingPages.GardenServices,
              servicesRoutingPages.GardenServicesDetails,
              servicesDetailsRoutingPages.PrepareForWinter
            ]"
            routerLinkActive="active-link"
            (click)="onMenuCloseClick()"
          >
            Przygotowanie do zimy
          </li>
        </ul>
      </li>
      <li class="menu-nav-element">
        <a
          class="element-text"
          [routerLink]="appRoutingPages.Realizations"
          routerLinkActive="active-link"
          (click)="onMenuCloseClick()"
        >
          realizacje
        </a>
      </li>
      <li class="menu-nav-element">
        <a
          class="element-text"
          [routerLink]="appRoutingPages.Contract"
          routerLinkActive="active-link"
          (click)="onMenuCloseClick()"
        >
          kontakt
        </a>
      </li>
    </ul>
  </nav>
</header>

