export enum ServicesRoutingPages {
  GardenServicesList = 'list',
  GardenServicesDetails = 'details',
}

export enum ServicesDetailsRoutingPages {
  StartTheSeason = 'start-the-season',
  YearRoundCare = 'year-round-care',
  PrepareForWinter = 'prepare-for-winter',
  IrrigationSystems = 'irrigation',
  Lawns = 'lawns',
  Plantings = 'plantings',
  CareAndRevitalization = 'care',
  Spraying = 'spraying',
  Drainage = 'drainage'
}

export enum AppRoutingPages {
  Home = 'home',
  AboutUs = 'about-us',
  GardenServices = 'services',
  Contract = 'contract',
  Realizations = 'realizations'
}
