import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {
  BreakpointDetectionService,
  Breakpoints
} from '@src/app/shared/services/breakpoint-detection.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-quick-menu-element',
  templateUrl: './quick-menu-element.component.html',
  styleUrls: [ './quick-menu-element.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickMenuElementComponent {
  public breakpoints: typeof Breakpoints = Breakpoints;
  public activeBreakpoint$: Observable<Breakpoints>;

  @Input() public upperTitle = null;
  @Input() public lowerTitle = null;
  @Input() public imageClassName = null;

  constructor(private breakpointDetectionService: BreakpointDetectionService) {
    this.activeBreakpoint$ = this.breakpointDetectionService.activeBreakpoint$;
  }
}
