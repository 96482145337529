<footer class="footer">
  <div
    *ngIf="(activeBreakpoint$ | async) === breakpoints.Mobile; else desktopFooter"
    class="mobile-footer-columns"
  >
    <div class="footer-column">
      <div class="description-group">
        <p class="desc">MD Garden Damian Masny</p>
        <p class="desc">ul. Chopina 2a</p>
        <p class="desc">05-430 Celestynów</p>
        <p class="desc">NIP: 532-202-31-56</p>
      </div>
      <div class="description-group">
        <p class="desc">telefon: 660 359 497</p>
        <p class="desc">email: kontakt@md-garden.pl</p>
      </div>
      <div class="logos-group">
        <i class="instagram-logo" (click)="onInstagramClick()"></i>
        <i class="facebook-logo" (click)="onFacebookClick()"></i>
      </div>
    </div>
  </div>

  <ng-template #desktopFooter>
    <div class="desktop-footer-wrapper">
      <div class="desktop-footer-columns">
        <div class="footer-column">
          <p class="column-header">MD GARDEN Damian Masny</p>
          <div class="column-description">
            <p class="desc">MD Garden</p>
            <p class="desc">ul. Chopina 2a</p>
            <p class="desc">05-430 Celestynów</p>
            <p class="desc">NIP: 532-202-31-56</p>
          </div>
        </div>
        <div class="footer-column">
          <p class="column-header">Dane kontaktowe</p>
          <div class="column-description">
            <p class="desc">telefon: +48 660 359 497</p>
            <p class="desc">email: kontakt@md-garden.pl</p>
          </div>
        </div>
        <div class="footer-column">
          <p class="column-header">Social media</p>
          <div class="logos-wrapper">
            <i class="instagram-logo" (click)="onInstagramClick()"></i>
            <i class="facebook-logo" (click)="onFacebookClick()"></i>
          </div>
        </div>
      </div>

    </div>
  </ng-template>
</footer>
